import React from "react";
import { Button } from "react-bootstrap";
import pen from "../Admin/assets/icons/pen.png";
import basket from "../Admin/assets/icons/basket.png";
import save from "../Admin/assets/icons/save.png";
import cancel from "../Admin/assets/icons/cross.png";

const AddButton = (props) => {
  return (
    <Button
      onClick={() => props.handleShow1(props?.id)}
      type="button"
      className="btn btn-primary me-1"
    >
      <img src={pen} className="pen" alt="" />
    </Button>
  );
};

const EditButton = (props) => {
  return (
    <Button
      onClick={() => props.handleShow1(props?.id)}
      type="button"
      className="btn btn-primary me-1"
    >
      <img src={pen} className="pen" alt="" />
    </Button>
  );
};

const DeletButton = (props) => {
  return (
    <button
      onClick={() => {
        props.showDeleteRecord(props?.id, props?.name);
      }}
      type="button"
      className="btn btn-danger"
    >
      <img src={basket} className="pen" alt="" />
    </button>
  );
};

const SaveButton = (props) => {
  return (
    <Button
      variant="success"
      type="submit"
      onClick={props.handleSubmit}
      className="btn btn-save"
    >
      <img src={save} className="save-img me-2" alt="" /> {props.name}
    </Button>
  );
};

const CancelButton = (props) => {
  return (
    <Button
      onClick={props.handleClose}
      type="button"
      variant="danger"
      className="btn btn-cancel me-2"
    >
      <img src={cancel} className="cancel-img" alt="" /> {props.name}
    </Button>
  );
};

const Submit = (props) => {
  return (
    <Button
      onClick={props.handleSubmit}
      type="button"
      className="btn btn-submit me-2"
    >
     { props.name}
    </Button>
  );
};

export { AddButton, EditButton, DeletButton, CancelButton, SaveButton, Submit };

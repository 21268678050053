import {
  getData,
  postData,
  putData,
  editOperationData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const getAllData = async (page, per_page, search) => {
  try {
    let url = `/operations/app-operations?`;

    if (page) {
      url += `&page=${page}`;
    }if (per_page) {
      url += `&per_page=${per_page}`;
    }

    if (search) {
      url += `&term=${search}`;
    }

    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Edit = async (id) => {
  try {
    let url = `/operations/app-operations/${id}`;
    return await getData(url);
  } catch (error) {
    console.error(error);
  }
};

export const Create = async (data) => {
  try {
    return await postData(`/operations/app-operations`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Update = async (id,data) => {
  try {
    return await putData(`/operations/app-operations/${id}`, data);
  } catch (error) {
    console.error(error);
  }
};

export const Delete = async (id) => {
  try {
    return await deleteData(`/operations/app-operations/${id}`);
  } catch (error) {
    console.error(error);
  }
};
